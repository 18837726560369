@media only screen and (max-width: 991px) {
  .navbar-collapse.collapse.show {
    overflow: auto;
  }
}
.lock-scroll {
  overflow: hidden;
}
#navbartopbg {
  background-color: #DB1B0D;
}
#navbarbg {
  background: #fff;
  box-shadow: 0px 1px 0px #d9dfe6;
}
#topleft {
  vertical-align: text-bottom;
  color: #FFFFFF !important;
}
#toptopleftsec {
  vertical-align: sub;
}
#indexbtn {
  z-index: 12;
}
.navcollapseclass {
  justify-content: center;
  z-index: 10;
}
.headertitles {
  font-family: Switzer-Medium;
  font-size: 16px !important;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: auto;
  color: #141c26 !important;
  white-space: nowrap !important;
}
@media only screen and (max-width: 500px) {
  .headertitles {
    font-size: 13px;
  }
  .announcecont{
    font-size: 13px;
  }
  .announcecont1{
    font-size: 20px !important;
    line-height: 22px !important;
  }
}
@media only screen and (max-width: 991px) {
  .headercenterforlargedevice {
    align-items: start !important;
  }
  .headertitles {
    text-align: start;
  }
}
.dropdown:hover .dropdown-menu {
  display: block;
}
@media only screen and (min-width: 992px) {
  .dropdown-menu.show {
    display: none;
  }
}
.headercenterforlargedevice {
  align-items: center;
}
.dropdown-toggle::after {
  display: none;
}
.dropdown-menu {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9dfe6;
  opacity: 1;
}
@media only screen and (max-width: 991px) {
  .dropdown-menu {
    border: none;
  }
}
.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #f4f6f8 !important;
  opacity: 1;
  color: #141c26 !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #f4f6f8 !important;
  color: #141c26 !important;
}
.headerdropdown {
  font-family: Switzer-Medium;
  font-size: 16px;
  letter-spacing: 0px;
  color: #141c26;
  opacity: 1;
  line-height: 24px;
}
@media only screen and (max-width: 500px) {
  .headerdropdown {
    font-size: 13px;
  }
}
.navbar-toggler:focus {
  box-shadow: none !important;
}
.dropdown-toggle {
  outline: none;
}
.callus {
  font-family: Switzer-Bold;
  letter-spacing: 0px;
  background-color: #DB1B0D;
  color: #FFFFFF;
  opacity: 1;
  font-size: 18px;
  text-align: center;
  
}

@media only screen and (max-width:450px){
  .eventimg{
    width: 33% !important;
    text-align: center;
  }
}
.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

}



.eventbutton {
  height: 37px;
  width: 121px;
  color: #fff;
  border: none;
  background: #db1b0d;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}

.callus a{
  font-family: Switzer-Bold;
  letter-spacing: 1px;
  
  color: #FFFFFF;
  opacity: 1;
  font-size: 18px;
  text-align: center;
  background-size: cover;
}

.toptopheader {
  font-family: Switzer-Medium;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 13px;
}
.toptopheader1 {
  font-family: Switzer-Bold;
  letter-spacing: 0px;
  color: #DB1B0D;
  opacity: 1;
  font-size: 16px;
}
.toptopheaderlinks {
  text-decoration: none;
  color: #FFFFFF;
}
.toptopheaderlinks:hover {
  color: #FFFFFF;
}
.toptopheader2 {
  font-family: Switzer-Medium;
  letter-spacing: 0px;
  color: #141C26;
  opacity: 1;
  font-size: 13px;
}
.toptopheaderlinks1 {
  text-decoration: underline !important;
  color: #FFFFFF;
}
.toptopheaderlinks1:hover {
  color: #FFFFFF;
}
.toptopheaderlinks2 {
  /* text-decoration: underline !important; */
  color: #DB1B0D;
}
/* .toptopheaderlinks2:hover {
  color: black;
} */
.toptopheaderlinks3{
  text-decoration: none !important;
  color: #141C26;
}
.toptopheaderlinks3:hover {
  color: #141C26;
}
@media only screen and (max-width: 991px) {
  .topheaderscroll {
    white-space: nowrap;
    overflow-x: scroll;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .toptopheader {
   font-size: 10px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .toptopheader {
   font-size: 11px !important;
  }
}
@media only screen and (min-width: 992px) {
  .headerscroll {
    float: right;
  }
}
@media (min-width: 768px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}
.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-radius: 50%;
  height: 36px;
  width: 36px;
}
.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}
.navbar-toggler span {
  margin: 0;
  padding: 0;
}
.toggler-icon {
  display: block;
  position: absolute;
  height: 2px;
  width: 60%;
  background: #141c26;
  border-radius: 1px;
  opacity: 1;
  left: 10px;
  top: 17px;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.middle-bar {
  margin-top: 0px;
}
/* when navigation is clicked */
.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}
.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}
.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}
.navbar-toggler.collapsed .top-bar {
  margin-top: -6px;
  transform: rotate(0deg);
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}
.navbar-toggler.collapsed .bottom-bar {
  margin-top: 6px;
  transform: rotate(0deg);
}
.activeHeader {
  color: #47b3e6 !important;
}
.activeHeaderborder {
  font-family: Switzer-Bold;
  opacity: 1;
}
.topdot {
  height: 4px;
  width: 4px;
}
@media (max-width: 991px) {
  .navbar-collapse {
    position: fixed;
    top: 0px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
  .navbar-collapse.collapsing {
    left: -75%;
    transition: height 0s ease;
  }
  .navbar-collapse.show {
    top: 0;
    background-color: #fff;
    left: 0;
    transition: left 300ms ease-in-out;
  }
  .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 500ms ease-in-out;
  }
}
.headerbutton {
  height: 35px;
  background: #F0DE36;
  width: 131px;
  border: none;
  font-size: 11px;
  color: #141c26;
}
.headerbuttonalink {
  color: #fff;
  text-decoration: none;
}
.headerbuttonalink:hover {
  color: #fff;
}
.border {
  border-right: none !important;
}
.activeLink {
  font-weight: 700 !important;
}
.borderRadius {
  border-radius: 0px !important;
}
@media (max-width: 991px) {
  .border {
    width: 100% !important;
  }
}
.navstyle{
  background: #fff;
  box-shadow: 0px 1px 0px #d9dfe6;
}
@media only screen and (max-width:450px){
  .logoimg{
    width: 100% !important;
  }
}
/* Announcement section */
.announcebtn{
  height: 35px;
  width: 140px;
  color: #000000;
  font-size: 14px;
  line-height: 34px;
  letter-spacing: 0;
  border: 0;
  background-color: #fdb910;
  /* animation: whatsnewkeyanimation 2s;
  animation-iteration-count: infinite; */
}
.delhibtn{
   animation: whatsnewkeyanimation 4s;
  animation-iteration-count: infinite;
  font-size: 18px;
  background: transparent;
  border: none;
}

@keyframes whatsnewkeyanimation {
  0% {
    background: #A60E13;
  }
  25% {
    background: #FFFFFF;
  }
  50% {
      background: #A60E13;
  }
  51% {
      background: #A60E13;
  }
  75% {
    background: #FFFFFF;
  }
  100% {
      background: #A60E13;
  }
}
.ulliclass1{
  padding-left: 11px;
  padding-bottom: 5px;
}
.ulclass1 {
  list-style: none; 
}
.ullispan1{
  top: -5px;
  position: relative;
}
@media only screen and (min-width:577px){
  .ullispan1{
    top: -6px;
    left:10px;
    right:10px;
  }
}
@media only screen and (max-width:576px){
  .ulclass1 li::before {
    position: relative;
    right: 4px !important;
  }
}
.ulclass1 li::before {
  content:url('../../assets/images/header/modalnew.png');
  vertical-align: super;
  margin-left: -13px;
}
.announcecont1{
  font-family: Switzer-Bold;
  font-size: 28px;
  color: #141C26;
  line-height: 42px;
  letter-spacing: 0;
  opacity: 1;
  text-align: left;
}
.announcecont2{
  font-family: Switzer-Medium;
  font-size: 16px;
  line-height: 10px;
  color: #141C26 !important;
  letter-spacing: 0;
  opacity: 1;
  text-decoration: none !important;
}
.redbtn{
  height: 28px;
  width: 73px;
  color: #FFFFFF;
  border: none;
  background: #DB1B0D;
  border-radius: 5px;
  font-size: 16px;
  opacity: 1;
}
.yellowbtn{
  height: 28px;
  width: 73px;
  color: #141C26;
  border: none;
  background: #F0DE36;
  border-radius: 5px;
  font-size: 16px;
  opacity: 1;
}
.bluebtn{
  height: 28px;
  width: 79px;
  color: #141C26;
  border: none;
  background: #64C8F0;
  border-radius: 5px;
  font-size: 16px;
  opacity: 1;
}

